import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'

const List = (props) => (
  <ul>
    {props.articles.map((article, i) => (
      <li key={i}>
        <Link
          to={`/${props.categorySlug}/${article.slug}`}
          activeClassName="isActive"
        >
          {article.title}
        </Link>
      </li>
    ))}
  </ul>
)

List.propTypes = {
  articles: PropTypes.array.isRequired
}

export default List
