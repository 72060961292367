import React from 'react';
import Link from 'gatsby-link';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import logo from '../images/logo.svg';

const Header = () => (
  <header className="header header--main">
    <div className="logo">
      <Link to="/" title="Return to Home">
        <img src={logo} alt="Carbon Logo" />
      </Link>
    </div>

    <nav className="header__nav">
      <ul className="header__nav--list">
        <li>
          <OutboundLink href="https://carbondmp.com?ref=docs">
            Homepage
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://app.carbondmp.com?ref=docs">
            Dashboard
          </OutboundLink>
        </li>
        <li>
          <OutboundLink href="https://glossary.carbondmp.com?ref=docs">
            Glossary
          </OutboundLink>
        </li>
      </ul>
    </nav>
  </header>
);

export default Header;
