import React from 'react'

const Footer = () => (
  <footer className="footer footer--main">
    <div className="container">
      <p>If you need further support please get in touch with your Carbon Account Manager or email us on support@carbondmp.com</p>
    </div>
    <div className="container">
      <p>Copyright &copy; {new Date().getFullYear()} Carbon (AI) Ltd. All rights reserved.</p>
    </div>
  </footer>
)

export default Footer
