import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import List from './List';
import { isPathActive } from '../utilities';

const Sidebar = ({ categories }) => (
  <nav>
    <ul>
      <li>
        <Link
          to={'/'}
          getProps={({ isCurrent }) =>
            isCurrent ? { className: 'isActiveGroup' } : null
          }
        >
          Overview
        </Link>
        <hr />
      </li>
      {categories.map((category, i) => (
        <li key={i}>
          <Link
            getProps={({ location: { pathname } }) =>
              isPathActive(pathname, category.slug)
                ? { className: 'isActiveGroup' }
                : null
            }
            to={`/${category.slug}/${category.articles[0].slug}`}
          >
            {category.name}
          </Link>
          <List articles={category.articles} categorySlug={category.slug} />
        </li>
      ))}
    </ul>
  </nav>
);

Sidebar.propTypes = {
  categories: PropTypes.array.isRequired
};

export default Sidebar;
