import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import '../styles/app.scss';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        allContentfulNavigation {
          edges {
            node {
              id
              categories {
                name
                slug
                articles {
                  title
                  slug
                  body {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Fragment>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'Put your audience data to work. Carbon is a cloud based Data Management Platform that allows you to collect, manage and leverage your audience data.'
            },
            {
              name: 'keywords',
              content: 'carbon, audience, data, dmp, management'
            }
          ]}
        >
          <link rel="stylesheet" href="//use.typekit.net/uon4hvf.css" />
        </Helmet>
        <Header />
        <section className="main">
          <div className="inner-container">
            <div className="sidebar colspan-3">
              <Sidebar
                categories={
                  data.allContentfulNavigation.edges[0].node.categories
                }
              />
            </div>
            <div className="colspan-9 content">{children}</div>
          </div>
        </section>
        <Footer />
      </Fragment>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
